import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Custom Http Interceptor that intercepts request and reposes related to the youtube api
 * Remove all the CORS and Authorization headers
 */

@Injectable()
export class ExternalApiInterceptor implements HttpInterceptor {
    listOfExternalAPIS = [
        environment?.youtube.baseUrl,
    ];

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const externalRequest = this.listOfExternalAPIS.filter(url => request.url.includes(url) && url !== '');

        if (externalRequest.length) {
            request = request.clone({
                withCredentials: false,
                headers: request.headers.delete('Authorization')
                    .delete('X-XSRF-TOKEN')
                    .delete('DSPACE-XSRF-TOKEN')
                    .delete('XSRF-TOKEN')
                    .delete('X-CORRELATION-ID')
                    .delete('CORRELATION-ID')
                    .delete('X-REFERRER')
            });

            return next.handle(request);
        }

        return next.handle(request);
    }
}
