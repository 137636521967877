<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer
      id="iiif-viewer"
      [object]="object"
      [searchable]="iiifSearchEnabled"
      [query]="iiifQuery$ | async"
    >
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <div class="pl-2">
    <ds-dso-page-version-button
      (newVersionEvent)="onCreateNewVersion()"
      [dso]="object"
      [tooltipMsgCreate]="'item.page.version.create'"
      [tooltipMsgHasDraft]="'item.page.version.hasDraft'"
    ></ds-dso-page-version-button>
    <ds-dso-page-edit-button
      [pageRoute]="itemPageRoute"
      [dso]="object"
      [tooltipMsg]="'item.page.edit'"
    ></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer
        [item]="object"
        [videoOptions]="mediaViewer.video"
      ></ds-media-viewer>
    </ng-container>
    <h2 class="item-page-title-field mr-auto">
      <ds-metadata-values
        [mdValues]="object?.allMetadata(['dc.title'])"
      ></ds-metadata-values>
    </h2>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.description']"
      [label]="'item.page.description'"
      [toUpperCase]="true"
    >
    </ds-generic-item-page-field>
    <ds-themed-item-page-file-section
      [item]="object"
    ></ds-themed-item-page-file-section>

    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.date.quadrennium']"
      [label]="'dc.date.quadrennium'"
    >
    </ds-generic-item-page-field>

    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-metadata-representation-list
      class="ds-item-page-mixed-author-field"
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataFields]="['dc.contributor.author', 'dc.creator']"
      [label]="'relationships.isAuthorOf' | translate"
    >
    </ds-metadata-representation-list>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['journal.title']"
      [label]="'item.page.journal-title'"
    >
    </ds-generic-item-page-field>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['journal.identifier.issn']"
      [label]="'item.page.journal-issn'"
    >
    </ds-generic-item-page-field>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['journalvolume.identifier.name']"
      [label]="'item.page.volume-title'"
    >
    </ds-generic-item-page-field>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.publisher']"
      [label]="'item.page.publisher'"
    >
    </ds-generic-item-page-field>

    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.subject']"
      [separator]="','"
      [label]="'item.page.subject'"
    >
    </ds-generic-item-page-field>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.identifier.citation']"
      [label]="'item.page.citation'"
    >
    </ds-generic-item-page-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'"
    >
    </ds-item-page-uri-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a
        class="btn btn-outline-primary"
        [routerLink]="[itemPageRoute + '/full']"
        role="button"
      >
        <i class="fas fa-info-circle"></i>
        {{ "item.page.link.full" | translate }}
      </a>
    </div>
  </div>
  <div class="col-xs-12 col-md-8">
    <pdf-list-viewer [item]="object"></pdf-list-viewer>
  </div>
</div>
