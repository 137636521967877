import { Component, Input } from '@angular/core';

import { Item } from '../../../../core/shared/item.model';

/**
 * This component can be used to represent metadata on a simple item page.
 * It expects one input parameter of type Item to which the metadata belongs.
 * This class can be extended to print certain metadata.
 */

@Component({
  templateUrl: './item-page-field.component.html',
})
export class ItemPageFieldComponent {
  /**
   * The item to display metadata for
   */
  @Input() item: Item;

  /**
   * Fields (schema.element.qualifier) used to render their values.
   */
  fields: string[];

  /**
   * Label i18n key for the rendered metadata
   */
  label: string;

  /**
   * Separator string between multiple values of the metadata fields defined
   * @type {string}
   */
  separator = '<br/>';

  /**
   * fields values to UpperCase
   */
  toUpperCase = false;
}
