<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer
      id="iiif-viewer"
      [object]="object"
      [searchable]="iiifSearchEnabled"
      [query]="iiifQuery$ | async"
    >
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values
      [mdValues]="object?.allMetadata(['dc.title'])"
    ></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-version-button
      (newVersionEvent)="onCreateNewVersion()"
      [dso]="object"
      [tooltipMsgCreate]="'item.page.version.create'"
      [tooltipMsgHasDraft]="'item.page.version.hasDraft'"
    ></ds-dso-page-version-button>
    <ds-dso-page-edit-button
      [pageRoute]="itemPageRoute"
      [dso]="object"
      [tooltipMsg]="'item.page.edit'"
    ></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-6">
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.description']"
      [label]="'item.page.description'"
      [toUpperCase]="true"
    >
    </ds-generic-item-page-field>

    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.subject']"
      [separator]="','"
      [label]="'item.page.subject'"
    >
    </ds-generic-item-page-field>
    <ds-generic-item-page-field
      [item]="object"
      [fields]="['dc.identifier.citation']"
      [label]="'item.page.citation'"
    >
    </ds-generic-item-page-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'"
    >
    </ds-item-page-uri-field>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <div>
      <a
        class="btn btn-outline-primary"
        [routerLink]="[itemPageRoute + '/full']"
        role="button"
      >
        <i class="fas fa-info-circle"></i>
        {{ "item.page.link.full" | translate }}
      </a>
    </div>
  </div>
</div>
