import { NgModule } from '@angular/core';
import { ENTRY_COMPONENTS as INTELLISYS } from './rd_senado/entry-components';
import { ENTRY_COMPONENTS as RD_SENADO_GLOSSARY } from './rd_senado_glossary/entry-components';
import { ENTRY_COMPONENTS as RD_SENADO_LEGISLATORS } from './rd_senado_legislators/entry-components';

const ENTRY_COMPONENTS = [
  ...INTELLISYS,
  ...RD_SENADO_GLOSSARY,
  ...RD_SENADO_LEGISLATORS,
];

/**
 * This module only serves to ensure themed entry components are discoverable. It's kept separate
 * from the theme modules to ensure only the minimal number of theme components is loaded ahead of
 * time
 */
@NgModule()
export class ThemedEntryComponentModule {
  static withEntryComponents() {
    return {
      ngModule: ThemedEntryComponentModule,
      providers: ENTRY_COMPONENTS.map((component) => ({ provide: component })),
    };
  }
}
