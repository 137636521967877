<ng-container *ngVar="bitstreams$ | async as bitstreams">
  <div *ngFor="let file of bitstreams; first as isFirst">
    <intellisys-flexpaper-pdf-viewer
      *ngIf="isFirst"
      [bitstream]="file"
      [item]="item"
    >
    </intellisys-flexpaper-pdf-viewer>
  </div>
</ng-container>
