import { getInfoModulePath } from '../app-routing-paths';

export const END_USER_AGREEMENT_PATH = 'end-user-agreement';
export const PRIVACY_PATH = 'privacy';
export const TERMS_PATH = 'terms';
export const FAQ_PATH = 'faq';
export const CONTACT_PATH = 'contact';
export const ABOUTUS_PATH = 'about-us';

export function getEndUserAgreementPath() {
    return getSubPath(END_USER_AGREEMENT_PATH);
}

export function getPrivacyPath() {
    return getSubPath(PRIVACY_PATH);
}

export function getTermsPath() {
    return getSubPath(TERMS_PATH);
}

export function getFAQPath() {
    return getSubPath(FAQ_PATH);
}

export function getContactPath() {
    return getSubPath(CONTACT_PATH);
}

export function getAboutUsPath() {
    return getSubPath(ABOUTUS_PATH);
}

function getSubPath(path: string) {
    return `${getInfoModulePath()}/${path}`;
}
