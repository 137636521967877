<div class="container">
  <div class="row">
    <div class="col-2" *ngIf="showThumbnail">
      <a
        *ngIf="linkType != linkTypes.None"
        [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
        rel="noopener noreferrer"
        [routerLink]="[itemPageRoute]"
        class="card-img-top full-width"
      >
        <div>
          <ds-thumbnail
            [thumbnail]="dso?.thumbnail | async"
            [limitWidth]="false"
            [defaultImage]="'assets/images/searchresult-placeholder.svg'"
          >
          </ds-thumbnail>
        </div>
      </a>
    </div>
    <div class="col-10">
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <ds-truncatable [id]="dso.id" *ngIf="object">
        <a
          *ngIf="linkType != linkTypes.None"
          [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
          rel="noopener noreferrer"
          [routerLink]="[itemPageRoute]"
          class="lead item-list-title dont-break-out"
          [innerHTML]="dsoTitle"
        ></a>
        <span
          *ngIf="linkType == linkTypes.None"
          class="lead item-list-title dont-break-out"
          [innerHTML]="dsoTitle"
        ></span>
        <span class="text-muted">
          <ds-truncatable-part [id]="dso.id" [minLines]="1">
            <ng-container
              *ngIf="
                dso.firstMetadataValue('dc.publisher') ||
                dso.firstMetadataValue('dc.date.issued')
              "
            >
              (<span
                *ngIf="dso.firstMetadataValue('dc.publisher')"
                class="item-list-publisher"
                [innerHTML]="firstMetadataValue('dc.publisher') + ', '"
              ></span>
              <span
                *ngIf="dso.firstMetadataValue('dc.date.issued')"
                class="item-list-date"
                [innerHTML]="firstMetadataValue('dc.date.issued')"
              ></span>
              )
            </ng-container>
            <span
              *ngIf="
                dso.allMetadata([
                  'dc.contributor.author',
                  'dc.creator',
                  'dc.contributor.*'
                ]).length > 0
              "
              class="item-list-authors"
            >
              <span
                *ngFor="
                  let author of allMetadataValues([
                    'dc.contributor.author',
                    'dc.creator',
                    'dc.contributor.*'
                  ]);
                  let last = last
                "
              >
                <span [innerHTML]="author"
                  ><span [innerHTML]="author"></span
                ></span>
                <span *ngIf="!last"> | </span>
              </span>
            </span>

            <span
              *ngIf="
                dso.allMetadata([
                  'dc.date',
                  'dc.identifier',
                  'dc.type',
                  'dc.subject',
                  'local.province',
                  'dc.date.quadrennium'
                ]).length > 0
              "
              class="item-list-authors"
            >
              <span
                *ngFor="
                  let other of allMetadataValues([
                    'dc.date',
                    'dc.identifier',
                    'dc.type',
                    'dc.subject',
                    'local.province',
                    'dc.date.quadrennium'
                  ]);
                  let last = last
                "
              >
                <span [innerHTML]="other"
                  ><span [innerHTML]="other"></span
                ></span>
                <span *ngIf="!last"> | </span>
              </span>
            </span>
          </ds-truncatable-part>
        </span>
        <div
          *ngIf="dso.firstMetadataValue('dc.description')"
          class="item-list-abstract"
        >
          <ds-truncatable-part [id]="dso.id" [minLines]="3"
            ><span
              class="text-uppercase"
              [innerHTML]="firstMetadataValue('dc.description')"
            ></span>
          </ds-truncatable-part>
        </div>
      </ds-truncatable>
    </div>
  </div>
</div>
