import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { BitstreamDataService } from 'src/app/core/data/bitstream-data.service';
import { PaginatedList } from 'src/app/core/data/paginated-list.model';
import { RemoteData } from 'src/app/core/data/remote-data';
import { Bitstream } from 'src/app/core/shared/bitstream.model';
import { Item } from 'src/app/core/shared/item.model';
import { getFirstCompletedRemoteData } from 'src/app/core/shared/operators';
import { hasValue } from 'src/app/shared/empty.util';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';

@Component({
  selector: 'pdf-list-viewer',
  templateUrl: './pdf-list-viewer.component.html',
  styleUrls: ['./pdf-list-viewer.component.scss'],
})
export class PdfListViewerComponent implements OnInit {
  @Input() item: Item;

  bitstreams$: BehaviorSubject<Bitstream[]>;

  currentPage: number;

  constructor(
    protected bitstreamDataService: BitstreamDataService,
    protected notificationsService: NotificationsService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.bitstreams$ = new BehaviorSubject([]);
    this.bitstreamDataService
      .findAllByItemAndBundleName(this.item, 'ORIGINAL')
      .pipe(getFirstCompletedRemoteData())
      .subscribe((bitstreamsRD: RemoteData<PaginatedList<Bitstream>>) => {
        if (bitstreamsRD.errorMessage) {
          this.notificationsService.error(
            this.translateService.get('file-section.error.header'),
            `${bitstreamsRD.statusCode} ${bitstreamsRD.errorMessage}`
          );
        } else if (hasValue(bitstreamsRD.payload)) {
          const current: Bitstream[] = this.bitstreams$.getValue();
          this.bitstreams$.next([...current, ...bitstreamsRD.payload.page]);
          this.currentPage === bitstreamsRD.payload.totalPages;
        }
      });
  }
}
