<div>
  <div class="modal-header">
    <img
      class="card-img-top"
      style="width: 150px"
      src="/assets/images/logo-senado horizontal-trans.png"
      alt="Card image cap"
    />
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body text-center p-4">
    <h3 class="p-2"><strong>Bienvenido a la Memoria Histórica</strong></h3>
    <!-- <p class="lead p-2">
      Le informamos que contamos con los documentos históricos de 1931 a 1998 y
      del 2005 hasta la fecha. Continuan en proceso de digitalización del 1999
      al 2004. Disculpe los inconvenientes.
    </p> -->
    <p class="lead p-2">
      Le informamos que contamos con los documentos históricos de 1931hasta la fecha.
    </p>
  </div>
  <div class="card-footer text-center">Seguimos trabajando para ti</div>
</div>
