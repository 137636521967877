import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Context } from 'src/app/core/shared/context.model';
import { Item } from 'src/app/core/shared/item.model';
import { ViewMode } from 'src/app/core/shared/view-mode.model';
import { UntypedItemComponent as BaseComponent } from 'src/app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { listableObjectComponent } from 'src/app/shared/object-collection/shared/listable-object/listable-object.decorator';

@listableObjectComponent(
  Item,
  ViewMode.StandalonePage,
  Context.Any,
  'rd_senado_legislators'
)
@Component({
  selector: 'ds-untyped-item',
  styleUrls: [
    './../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component.scss',
  ],
  templateUrl: './untyped-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UntypedItemComponent extends BaseComponent {}
