import { Component, Input, OnInit } from '@angular/core';
import { Bitstream } from '../../core/shared/bitstream.model';
import {
  getBitstreamDownloadRoute,
  getBitstreamRequestACopyRoute,
} from '../../app-routing-paths';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../core/data/feature-authorization/feature-id';
import { map } from 'rxjs/operators';
import {
  of as observableOf,
  combineLatest as observableCombineLatest,
  Observable,
} from 'rxjs';
import { Item } from '../../core/shared/item.model';
import { hasValue, isNotEmpty } from 'src/app/shared/empty.util';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'intellisys-flexpaper-pdf-viewer',
  templateUrl: './intellisys-flexpaper-pdf-viewer.html',
  styleUrls: ['./intellisys-flexpaper-pdf-viewer.scss'],
})

/**
 * Component displaying a download link
 * When the user is authenticated, a short-lived token retrieved from the REST API is added to the download link,
 * ensuring the user is authorized to download the file.
 */
export class IntellisysFlexpaperPdfViewer implements OnInit {
  /**
   * Optional bitstream instead of href and file name
   */
  @Input() bitstream: Bitstream;

  @Input() item: Item;

  /**
   * Additional css classes to apply to link
   */
  @Input() cssClasses = '';

  /**
   * A boolean representing if link is shown in same tab or in a new one.
   */
  @Input() isBlank = false;

  @Input() enableRequestACopy = true;

  bitstreamPath$: Observable<{
    routerLink: string;
    queryParams: any;
  }>;

  canDownload$: Observable<boolean>;

  pdf_url: SafeResourceUrl;

  constructor(
    private authorizationService: AuthorizationDataService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.enableRequestACopy) {
      this.canDownload$ = this.authorizationService.isAuthorized(
        FeatureID.CanDownload,
        isNotEmpty(this.bitstream) ? this.bitstream.self : undefined
      );
      const canRequestACopy$ = this.authorizationService.isAuthorized(
        FeatureID.CanRequestACopy,
        isNotEmpty(this.bitstream) ? this.bitstream.self : undefined
      );
      this.bitstreamPath$ = observableCombineLatest([
        this.canDownload$,
        canRequestACopy$,
      ]).pipe(
        map(([canDownload, canRequestACopy]) =>
          this.getBitstreamPath(canDownload, canRequestACopy)
        )
      );
    } else {
      this.bitstreamPath$ = observableOf(this.getBitstreamDownloadPath());
      this.canDownload$ = observableOf(true);
    }
    this.pdf_url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'assets/flexpaper/template.html?path=' +
        this.getBitstreamDownloadPath().routerLink
    );
  }

  getBitstreamPath(canDownload: boolean, canRequestACopy: boolean) {
    if (!canDownload && canRequestACopy && hasValue(this.item)) {
      return getBitstreamRequestACopyRoute(this.item, this.bitstream);
    }
    return this.getBitstreamDownloadPath();
  }

  getBitstreamDownloadPath() {
    return {
      routerLink: getBitstreamDownloadRoute(this.bitstream),
      queryParams: {},
    };
  }
}
