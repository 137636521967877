<ul class="navbar-nav" [ngClass]="{ 'mr-auto': (isXsOrSm$ | async) }">
  <li
    *ngIf="
      (isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)
    "
    class="nav-item"
  >
    <div
      ngbDropdown
      display="dynamic"
      placement="bottom-right"
      class="d-inline-block"
      @fadeInOut
    >
      <a
        href="#"
        role="button"
        [attr.aria-label]="'nav.logout' | translate"
        (click)="$event.preventDefault()"
        [title]="'nav.logout' | translate"
        class="px-1"
        ngbDropdownToggle
      >
        <i class="fas fa-user-circle fa-lg fa-fw"></i
      ></a>
      <div
        class="logoutDropdownMenu"
        ngbDropdownMenu
        [attr.aria-label]="'nav.logout' | translate"
      >
        <ds-user-menu></ds-user-menu>
      </div>
    </div>
  </li>
  <li *ngIf="(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">
    <a
      id="logoutLink"
      role="button"
      [attr.aria-label]="'nav.logout' | translate"
      [title]="'nav.logout' | translate"
      routerLink="/logout"
      routerLinkActive="active"
      class="px-1"
    >
      <i class="fas fa-user-circle fa-lg fa-fw"></i>
      <span class="sr-only">(current)</span>
    </a>
  </li>
</ul>
