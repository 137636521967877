import { Component, Injector } from '@angular/core';
import { slideMobileNav } from '../shared/animations/slide';
import { MenuComponent } from '../shared/menu/menu.component';
import { MenuService } from '../shared/menu/menu.service';
import { MenuID, MenuItemType } from '../shared/menu/initial-menus-state';
import { TextMenuItemModel } from '../shared/menu/menu-item/models/text.model';
import { LinkMenuItemModel } from '../shared/menu/menu-item/models/link.model';
import { HostWindowService } from '../shared/host-window.service';
import { BrowseService } from '../core/browse/browse.service';
import { getFirstCompletedRemoteData } from '../core/shared/operators';
import { PaginatedList } from '../core/data/paginated-list.model';
import { BrowseDefinition } from '../core/shared/browse-definition.model';
import { RemoteData } from '../core/data/remote-data';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav],
})
export class NavbarComponent extends MenuComponent {
  /**
   * The menu ID of the Navbar is PUBLIC
   * @type {MenuID.PUBLIC}
   */
  menuID = MenuID.PUBLIC;

  constructor(
    protected menuService: MenuService,
    protected injector: Injector,
    public windowService: HostWindowService,
    public browseService: BrowseService
  ) {
    super(menuService, injector);
  }

  ngOnInit(): void {
    this.createMenu();
    super.ngOnInit();
  }

  /**
   * Initialize all menu sections and items for this menu
   */
  createMenu() {
    const menuList: any[] = [
      /* Communities & Collections tree */
      {
        id: `browse_global_communities_and_collections`,
        active: false,
        visible: true,
        index: 0,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.browse_global_communities_and_collections`,
          link: `/community-list`,
        } as LinkMenuItemModel,
      },
    ];
    // Read the different Browse-By types from config and add them to the browse menu
    this.browseService
      .getBrowseDefinitions()
      .pipe(getFirstCompletedRemoteData<PaginatedList<BrowseDefinition>>())
      .subscribe(
        (browseDefListRD: RemoteData<PaginatedList<BrowseDefinition>>) => {
          if (browseDefListRD.hasSucceeded) {
            browseDefListRD.payload.page.forEach(
              (browseDef: BrowseDefinition) => {
                menuList.push({
                  id: `browse_global_by_${browseDef.id}`,
                  parentID: 'browse_global',
                  active: false,
                  visible: true,
                  model: {
                    type: MenuItemType.LINK,
                    text: `menu.section.browse_global_by_${browseDef.id}`,
                    link: `/browse/${browseDef.id}`,
                  } as LinkMenuItemModel,
                });
              }
            );
            menuList.push(
              /* Browse */
              {
                id: 'browse_global',
                active: false,
                visible: true,
                index: 1,
                model: {
                  type: MenuItemType.TEXT,
                  text: 'menu.section.browse_global',
                } as TextMenuItemModel,
              }
            );
          }
          menuList.forEach((menuSection) =>
            this.menuService.addSection(
              this.menuID,
              Object.assign(menuSection, {
                shouldPersistOnRouteChange: true,
              })
            )
          );
        }
      );
  }
}
