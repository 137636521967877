import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WelcomeMessageService } from 'src/app/core/welcome-message/welcome-message.service';

/**
 * Component to wrap a button - to select the entire repository -
 * and a list of parent communities - for scope selection
 * inside a modal
 * Used to select a scope
 */
@Component({
  selector: 'ds-welcome-modal',
  styleUrls: ['./welcome-modal.component.scss'],
  templateUrl: './welcome-modal.component.html',
})
export class WelcomeModalComponent {
  constructor(
    protected activeModal: NgbActiveModal,
    protected welcomeMessageService: WelcomeMessageService
  ) {}

  close() {
    this.welcomeMessageService.setFirstAccessOfTheDay(false);
    this.activeModal.close('success');
  }
}
