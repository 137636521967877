import { Injectable } from '@angular/core';
import { CookieAttributes } from 'js-cookie';
import { CookieService } from '../services/cookie.service';

export const FIRST_ACCESS_OF_THE_DAY_COOKIE = 'firstAccessOfTheDay';

@Injectable()
export class WelcomeMessageService {
  constructor(protected cookie: CookieService) {}

  /**
   * Retrieve the language from a cookie
   */
  getFirstAccessOfTheDayCookie(): string {
    return this.cookie.get(FIRST_ACCESS_OF_THE_DAY_COOKIE);
  }
  /**
   * Is the first accss of the day?
   */
  isFirstAccessOfTheDay(): boolean {
    return this.cookie.get(FIRST_ACCESS_OF_THE_DAY_COOKIE);
  }

  /**
   * Set the FIRST_ACCESS_OF_THE_DAY_COOKIE cookie's state
   * @param isFirstAccess
   */
  setFirstAccessOfTheDay(isFirstAccess: boolean): boolean {
    // Add 1 hour to the current date
    const expireDate = Date.now() + 1000 * 60 * 60;
    // Set the cookie expire date
    const expires = new Date(expireDate);
    const days = 1;
    const options: CookieAttributes = { expires: days };

    try {
      this.cookie.set(FIRST_ACCESS_OF_THE_DAY_COOKIE, isFirstAccess, options);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Remove the FIRST_ACCESS_OF_THE_DAY_COOKIE cookie
   */
  removeCookieAccepted() {
    this.cookie.remove(FIRST_ACCESS_OF_THE_DAY_COOKIE);
  }
}
