<div>
  <div class="modal-header">
    {{
      "dso-selector." +
        action +
        "." +
        objectType.toString().toLowerCase() +
        ".head" | translate
    }}
    <button
      type="button"
      class="close"
      (click)="selectObject(undefined)"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <button
      class="btn btn-outline-primary btn-lg btn-block"
      (click)="selectObject(undefined)"
    >
      {{
        "dso-selector." +
          action +
          "." +
          objectType.toString().toLowerCase() +
          ".button" | translate
      }}
    </button>
    <h3 class="position-relative py-1 my-3 font-weight-normal">
      <hr />
      <div
        id="create-community-or-separator"
        class="text-center position-absolute w-100"
      >
        <span class="px-4 bg-white">También puede:</span>
      </div>
    </h3>

    <h5 class="px-2">
      {{
        "dso-selector." +
          action +
          "." +
          objectType.toString().toLowerCase() +
          ".input-header" | translate
      }}
    </h5>
    <ds-dso-selector
      [currentDSOId]="dsoRD?.payload.uuid"
      [types]="selectorTypes"
      (onSelect)="selectObject($event)"
    ></ds-dso-selector>
  </div>
</div>
