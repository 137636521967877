<div class="card">
  <a
    *ngIf="linkType != linkTypes.None"
    [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
    rel="noopener noreferrer"
    [routerLink]="['/collections/', object.id]"
    class="card-img-top"
  >
    <ds-thumbnail
      [thumbnail]="(object.logo | async)?.payload"
      [limitWidth]="false"
    >
    </ds-thumbnail>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top">
    <ds-thumbnail
      [thumbnail]="(object.logo | async)?.payload"
      [limitWidth]="false"
    >
    </ds-thumbnail>
  </span>
  <div class="card-body">
    <ds-truncatable-part
      [id]="object.id"
      [minLines]="2"
      [maxLines]="2"
      [fixedHeight]="true"
      type="h4"
    >
      <h6 class="card-title">{{ object.name }}</h6>
    </ds-truncatable-part>

    <p *ngIf="object.shortDescription" class="card-text">
      {{ object.shortDescription }}
    </p>
    <div *ngIf="linkType != linkTypes.None" class="text-center">
      <a
        [target]="linkType == linkTypes.ExternalLink ? '_blank' : '_self'"
        rel="noopener noreferrer"
        [routerLink]="['/collections/', object.id]"
        class="lead btn btn-primary viewButton"
        >{{ "mydspace.view-btn" | translate }}</a
      >
    </div>
  </div>
</div>
